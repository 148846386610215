import '../scripts/wdyr';

import { useEffect } from 'react';
import Head from 'next/head';
import App from 'next/app';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import Layout from '../components/Layout/Layout';
import langEnMessages from '../locales/en';
import theme from '../styles/theme';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

function Application({ Component, pageProps, locale }) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    // eslint-disable-next-line no-undef
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
      </Head>

      <CssBaseline />
      <IntlProvider
        messages={langEnMessages}
        locale={locale}
        defaultLocale="en"
      >
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </IntlProvider>
    </ThemeProvider>
  );
}

Application.propTypes = {
  Component: PropTypes.elementType.isRequired,
  locale: PropTypes.string.isRequired,
  pageProps: PropTypes.object.isRequired,
};

Application.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { router } = appContext;
  const { locale } = router;

  return {
    ...appProps,
    locale,
  };
};

export function reportWebVitals(metric) {
  console.log(metric);
}

export default Application;
